article.page {
  margin: 3rem 0;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  .ui.header {
    padding: 0 1rem;
  }
  & > * {
    text-align: left;
    padding: 1rem;
    width: 100%;
    padding: 0 1rem;
    &:not(.alignfull) {
      max-width: 60rem;
    }
    &.alignwide {
      max-width: 70rem;
    }
  }
  & > ul, & > ol {
    padding-left: 2rem;
  }
}
article.textfrontpage {
  margin: 0;
}

.noVerticalPadding {
  padding-top: 0;
  padding-bottom: 0;
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  clip-path: none;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
  outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: @largestMobileScreen) {
  .flexReverse {
    display: flex;
    // ToDo: fix this, doesn't seem to work
    flex-direction: row-reverse;
  }
}
/*--------------------------------------------------------------
# Infinite scroll
--------------------------------------------------------------*/
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
  /* Theme Footer (when set to scrolling) */
  display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
  display: block;
}


/* Make sure logo link wraps around logo image. */
.custom-logo-link {
  display: inline-block;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

.wp-caption-text {
  text-align: center;
}

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
.gallery {
  margin-bottom: 1.5em;
}

.gallery-item {
  display: inline-block;
  text-align: center;
  vertical-align: top;
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}
.gallery-caption {
  display: block;
}
.has-text-align-center {
  text-align: center;
}
.has-text-align-right {
  text-align: right;
}

/* FRONTPAGE HOMEPAGE */
article.frontpage {
  margin-top: -3rem;
  margin-bottom: -3rem;
  .alignfull {
    padding-bottom: 0 !important;
  }
}
.full-height-block {
  z-index: 1;
  position: relative;
  min-height: calc(100vh - 3rem) !important; /* Fallback for browsers that do not support Custom Properties */
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 0 !important;
  & > div {
    flex: 1 1 auto;
    padding: 1rem;
    max-width: 60rem;
  }
  .wp-block-columns {
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: relative;
  }
}

.wp-block-cover-image .wp-block-cover__inner-container, .wp-block-cover .wp-block-cover__inner-container {
  color: @textColor !important;
}

.wp-block-cover-image .wp-block-cover-image-text, .wp-block-cover-image .wp-block-cover-text, .wp-block-cover-image h2, .wp-block-cover .wp-block-cover-image-text, .wp-block-cover .wp-block-cover-text, .wp-block-cover h2 {
  max-width: none !important;
}

.homepage-block {
  background: rgba(255,255,255,0.8);
  padding: 1rem 2rem !important;
  align-items: flex-end;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  //transform: skewX(-50deg);
  //transform-origin: 100% 100%;
  position: absolute !important;
  right: 2rem;
  bottom: 2rem;
  width: 25rem !important;
  .wp-block-group__inner-container {
    //transform: skewX(50deg);
    //transform-origin: 100% 100%;
  }
  a i {
    margin: 0 0 1rem !important;
  }
}

@media (max-width: @largestMobileScreen) {
  .centerCol {
    flex: 0 1 auto;
  }
}

.circleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1rem;
  max-height: 30rem;
  max-width: 30rem;
  width: 35vw;
  height: 35vw;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: @subtleShadow;
  background: @white;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0,0,0,.4),0 0 4px rgba(0,0,0,.05),-1px 2px 16px rgba(0,0,0,.05),-2px 4px 37px rgba(0,0,0,.05),-4px 8px 66px rgba(0,0,0,.05);
  //-webkit-mask-image: -webkit-radial-gradient(white, black); // Fix circle on Safari
  margin: auto !important;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  @media (max-width: @widescreenMonitorBreakpoint) {
    height: 25rem;
    width: 25rem;
  }
  @media (max-width: @largeMonitorBreakpoint) {
    height: 20rem;
    width: 20rem;
  }
}
video {
  cursor: pointer;
}
.videoPlayIcon {
  transition: all @defaultDuration @defaultEasing;
  opacity: 0 !important;
  font-size: 3rem !important;
  margin-left: -3rem !important;
  margin-top: -3rem !important;
  padding: 1.5rem 1.5rem 1.5rem 1.9rem;
  width: 6rem !important;
  height: 6rem !important;
  color: @popColor;
  z-index: 9;
  position: absolute;
  transform: scale(1.1);
  border-radius: 50%;
  background: @white;
  pointer-events: none;
}
.videoWrapper.paused {
  .videoPlayIcon {
    opacity: 0.9 !important;
    transform: scale(0.9);
    pointer-events: none;
  }
}

.backgroundSVG {
  z-index: 1;
  position: absolute;
  bottom: 0;
  top: -1rem;
  height: calc(100% + 4rem);
  @media (max-width: @largestMobileScreen) {
    top: 0;
    height: calc(100% + 2rem);
  }
  width: 100%;
  left: 0;
  pointer-events: none;
  opacity: 0.3;
  margin: -1rem 0;
  overflow: visible !important;
  path {
    visibility: hidden;
    fill: none;
    stroke-width: 1px;
    stroke: @secondaryColor;
  }
  &.visible {
    path {
      visibility: visible;
    }
  }
}

.backgroundLines {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.1;
  path {
    stroke: @popColor;
    transition: all ease-in-out 2s;
    transition-delay: 0s;
    stroke-width: 0;
    opacity: 0;
  }
  path:nth-of-type(2n) {
    transition-delay: .3s;
  }
  path:nth-of-type(3n) {
    transition-delay: .6s;
  }
  path:nth-of-type(4n) {
    transition-delay: .9s;
  }
  path:nth-of-type(5n) {
    transition-delay: .12s;
  }
  &.visible path {
    opacity: 1;
    stroke-width: 1px;
  }
}

@backgroundOpacity: 0.2;
.array() {
  pale-pink: rgba(247, 141, 167, @backgroundOpacity);
  vivid-red: rgba(207, 46, 46, @backgroundOpacity);
  luminous-vivid-orange: rgba(255, 105, 0, @backgroundOpacity);
  luminous-vivid-amber: rgba(252, 185, 0, @backgroundOpacity);
  light-green-cyan: rgba(123, 220, 181, @backgroundOpacity);
  vivid-green-cyan: rgba(0, 208, 132, @backgroundOpacity);
  pale-cyan-blue: rgba(142, 209, 252, @backgroundOpacity);
  vivid-cyan-blue: rgba(6, 147, 227, @backgroundOpacity);
  vivid-purple: rgba(155, 81, 224, @backgroundOpacity);
  very-light-gray: rgba(238, 238, 238, @backgroundOpacity);
  cyan-bluish-gray: rgba(171, 184, 195, @backgroundOpacity);
  very-dark-gray: rgba(49, 49, 49, @backgroundOpacity);
}

each(.array(), {
  .has-@{key}-background-color {
    background-color: @value !important;
  }
});

.menu-sub-item {
  padding-left: 3rem;
}
#content {
  .ui.rail {
    margin-top: 1rem;
  }
}

.noStack {
  flex-wrap: nowrap !important;
}

.mapBackground {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;

  // ToDo: make this better
  pointer-events: none;
  .leaflet-control-container {
    display: none !important;
  }
}
/*******************************
         Theme Overrides
*******************************/
@media (max-width: @largestMobileScreen) {
  #Trove .ui.stackable.cards .card:first-child {
    margin-top: @cardSpacing * 2 !important;
  }
}
a.ui.card {
  color: @textColor;
  text-decoration: none;
}
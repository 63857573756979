/*******************************
         Site Overrides
*******************************/

.ui.pagination.menu .item {
  justify-content: center;
  align-content: center;
}

.right.menu .dropdown .menu {
  left: auto;
  right: 0;
}